import { useState } from 'react'
import { Box, HStack, VStack, useBreakpointValue } from '@chakra-ui/react'
import { BannerSlidersType } from '@Types/contentstack'
import { ContentstackBannerFull } from 'composable/components/contentstack/banner-full'
import { ContentstackBannerSplit } from 'composable/components/contentstack/banner-split'
import { ContentstackBannerTextOnly } from 'composable/components/contentstack/banner-text-only'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { BaseProps } from './types'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../carousel'
import { calculateFontStyles } from '../contentstack/utils'

export interface BannerSliderProps extends BaseProps {
  sliderTitle?: string
  title_font_family?: string
  title_font_size?: number
  eyebrow?: string
  heading_alignment?: string
  description?: string
  banners: BannerSlidersType[]
  desktopContainerWidth?: string
  analyticsTrackingData?: string
}

export const BannerSlider = ({
  sliderTitle,
  title_font_family,
  title_font_size,
  heading_alignment,
  eyebrow,
  description,
  banners,
  desktopContainerWidth,
  analyticsTrackingData,
  isMobileDevice,
}: BannerSliderProps) => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const [isLoaded, setIsLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const showTextBox = eyebrow || sliderTitle || description

  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: true,
    mode: 'free',
  })

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(title_font_size || 400)

  const textAlignment = setTextAlign(heading_alignment)

  const renderBanners = (banner: any & { content_type: any }, i: number) => {
    const contentType = banner?.content_type?.uid

    return (
      <CarouselSlide key={banner.uid} aria-label={`Banner slider: Slide no ${i + 1}`} width="100%" minWidth="100%">
        {contentType === 'component_banner_full' && (
          <ContentstackBannerFull {...banner} analyticsTrackingData={analyticsTrackingData} relativeIndex={i + 1} />
        )}
        {contentType === 'component_banner_split' && (
          <ContentstackBannerSplit {...banner} analyticsTrackingData={analyticsTrackingData} relativeIndex={i + 1} />
        )}
        {contentType === 'component_banner_text_only' && (
          <ContentstackBannerTextOnly {...banner} analyticsTrackingData={analyticsTrackingData} relativeIndex={i + 1} />
        )}
      </CarouselSlide>
    )
  }

  if (!banners?.length) return <></>

  return isMobile ? (
    <>
      {showTextBox && (
        <VStack width="100%" textAlign="center" gap={3} mt={4}>
          {eyebrow && <Box textStyle="eyebrow-75">{eyebrow}</Box>}
          {sliderTitle && (
            <Box
              fontFamily={title_font_family || 'Libre Franklin'}
              fontSize={_titleFontSize}
              fontWeight={titleFontWeight}
              lineHeight={titleLineHeight}
            >
              {sliderTitle}
            </Box>
          )}
          {description && <Box textStyle="body-300">{description}</Box>}
        </VStack>
      )}

      <Carousel ref={ref} my={5} aria-label={`Slider`}>
        {banners?.map((banner, i) => renderBanners(banner, i))}
      </Carousel>

      <HStack position="relative" width="full" justify="center" display="flex" gap={0} mt={4}>
        {banners.map((_, idx) => (
          <Box
            flexBasis="100%"
            w={0}
            tabIndex={0}
            aria-label={`Banner slider: Slide no ${idx + 1}`}
            cursor="pointer"
            key={idx}
            height="2px"
            bg={currentSlide === idx ? 'text' : 'shading.300'}
            onClick={() => slider.current?.moveToIdx(idx)}
            onKeyPress={() => slider.current?.moveToIdx(idx)}
          />
        ))}
      </HStack>
    </>
  ) : (
    <Box
      position="relative"
      flexDirection="column"
      p={12}
      mx={desktopContainerWidth === 'Full' ? 4 : 0}
      gap={9}
      maxW={desktopContainerWidth}
    >
      {showTextBox && (
        <VStack width="100%" textAlign={textAlignment} gap={3} my={4}>
          {eyebrow && (
            <Box width="100%" textStyle="eyebrow-75">
              {eyebrow}
            </Box>
          )}
          {sliderTitle && (
            <Box
              width="100%"
              fontFamily={title_font_family || 'Libre Franklin'}
              fontSize={_titleFontSize}
              fontWeight={titleFontWeight}
              lineHeight={titleLineHeight}
            >
              {sliderTitle}
            </Box>
          )}
          {description && (
            <Box width="100%" textStyle="body-300">
              {description}
            </Box>
          )}
        </VStack>
      )}

      <Box display="flex" justifyContent="end" alignItems="center" width="100%">
        <CarouselIconButton
          width={12}
          height={12}
          background="shading.100"
          borderRadius={1}
          onClick={() => slider.current?.prev()}
          icon={<IoChevronBackOutline />}
          aria-label="Previous Slide"
          position="absolute"
          left={0}
          zIndex={2}
        />

        <Carousel ref={ref} mx={4} width="100%">
          {banners?.map((banner, i) => renderBanners(banner, i))}
        </Carousel>

        <CarouselIconButton
          width={12}
          height={12}
          background="shading.100"
          borderRadius={1}
          onClick={() => slider.current?.next()}
          icon={<IoChevronForwardOutline />}
          aria-label="Next Slide"
          position="absolute"
          right={0}
          zIndex={2}
        />
      </Box>
    </Box>
  )
}

const setTextAlign = (aligment: string) => {
  switch (aligment) {
    case 'left':
      return 'start'
    case 'right':
      return 'end'
    default:
      return 'center'
  }
}
