import { BoxProps } from '@chakra-ui/react'
import type { ContentstackBannerPromotion as IContentstackBannerPromotion } from '@Types/contentstack'
import { BannerPromotion } from '../cms-components/banner-promotion'

export interface ComponentBannerPromotionProps extends IContentstackBannerPromotion {
  root?: Omit<BoxProps, 'children'>
}

export const ContentstackBannerPromotion = (props: ComponentBannerPromotionProps) => {
  return <BannerPromotion {...props} />
}
