import { useEffect, useState } from 'react'
import { ContentstackProductSlider as ContentstackProductSliderProps } from '@Types/contentstack'
import { Product } from '@Types/product/Product'
import { ProductSlider } from 'composable/components/product-slider'
import { getProductsByIds } from 'frontastic/actions/product'

export const ContentstackProductList = ({
  title,
  subtitle,
  products,
  cta_label,
  cta_link,
  container_eyebrow,
  container_title,
  container_description,
  heading_alignment,
  title_font_family,
  title_font_size,
}: ContentstackProductSliderProps) => {
  const [productList, setProductList] = useState<ProductSlider[]>()

  useEffect(() => {
    fetchProducts(products)
  }, [])

  const fetchProducts = async (products) => {
    const productIds = products?.data?.map((product) => product.id)
    const productListResutls = await getProductsByIds(productIds)
    const productListMapped = productListResutls?.map((item: Product) => {
      const firstVariant = item.variants[0]
      return {
        href: `/p/${item.slug}`,
        imageUrl: firstVariant.images[0] ?? '',
        brand: firstVariant?.attributes?.brand ?? '',
        title: item.name ?? '',
        price: firstVariant.price,
      }
    })
    setProductList(productListMapped)
  }

  if (!products?.data || !productList) return <></>
  return (
    <ProductSlider
      title={title ?? 'Products'}
      variant="default"
      productList={productList}
      subtitle={subtitle}
      callToAction={{
        children: !cta_label || cta_label === '' ? 'Shop all' : cta_label,
        ctaLink: cta_link ?? '',
      }}
      containerEyebrow={container_eyebrow}
      containerDescription={container_description}
      containerTitle={container_title}
      headingAlignment={heading_alignment}
      titleFontFamily={title_font_family}
      titleFontSize={title_font_size}
    />
  )
}
