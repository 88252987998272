import React, { useState, useEffect } from 'react'
import { VStack } from '@chakra-ui/react'
import { SidebarAccordion } from '../sidebar/sidebar-accordion'
import { SidebarNavGeneric } from '../sidebar/sidebar-generic'

export interface AccountDashboardNavProps {
  state?: 'Expanded' | 'Collapsed'
  title: string
  items: any
}

export const DEFAULT_ACCOUNT_DASHBOARD_PATH = '/account/dashboard'

export const GenericNav = ({ state = 'Collapsed', title, items }: AccountDashboardNavProps) => {
  const [activeItemIdx, setActiveItemIdx] = useState<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset
      const windowHeight = window.innerHeight
      const activeSection = items.findIndex((item) => {
        const sectionElement = document.getElementById(item.slug)
        if (sectionElement) {
          const sectionTop = sectionElement.getBoundingClientRect().top
          const sectionBottom = sectionTop + sectionElement.offsetHeight
          return sectionTop < windowHeight * 0.5 && sectionBottom > windowHeight * 0.5
        }
        return false
      })
      setActiveItemIdx(activeSection)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [items])

  return (
    <>
      {/* Desktop */}
      <VStack
        spacing={'0'}
        width={'full'}
        alignItems={'stretch'}
        display={{ base: 'none', md: 'flex' }}
        position="sticky"
        top="0"
      >
        <SidebarNavGeneric size="Large" title={title} items={items} activeItem={activeItemIdx} />
      </VStack>

      {/* Mobile */}
      <VStack spacing={'0'} width={'full'} alignItems={'stretch'} display={{ base: 'flex', md: 'none' }}>
        {items?.map((item) => {
          if (!item) return null
          return (
            <SidebarAccordion key={`sidebarAccordion_${item.uid}`} label={item.title || ''}>
              <SidebarNavGeneric size="Small" activeItem={item} title={title} items={items} />
            </SidebarAccordion>
          )
        })}
      </VStack>
    </>
  )
}
