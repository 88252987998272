import { useEffect, useState } from 'react'
import { Skeleton } from '@chakra-ui/react'
import { ContentstackFooterBanner as ContentstackFooterBannerProps } from '@Types/contentstack'
import FooterBannerTastic from 'frontastic/tastics/composable/footer-banner'
import useContentstack, { Entry } from '../hooks/useContentstack'

export const COMPONENT_TEXT_CARD = 'component_text_card'

export const ContentstackFooterBanner = ({ children, analyticsTrackingData }: ContentstackFooterBannerProps) => {
  const [contentList, setContentList] = useState<Entry[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    getFooterBannerData(children)
  }, [])

  const getFooterBannerData = async (items) => {
    try {
      const itemsResults = items?.map((item) => getEntryByUid(COMPONENT_TEXT_CARD, item.uid))
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Footer Banner data', err)
    }
  }
  if (isLoading) return <Skeleton h={{ base: '864px', lg: '216px' }} />
  if (contentList) {
    return <FooterBannerTastic items={contentList} analyticsTrackingData={analyticsTrackingData} />
  }
}
