export const useCalculateHeightBanner = (contentList) => {
  const MAX_NUMBER_OF_CHARACTERS_PER_LINE = 50
  const MINIMUM_HEIGHT = 25

  if (!contentList) return { heightBanner: `${MINIMUM_HEIGHT}px` }

  let banerTextLines = 0
  contentList.forEach((textLine) => {
    const text = `${textLine.text} ${textLine.link_1.title} ${textLine.link_2.title}`
    const textLines = text.length / MAX_NUMBER_OF_CHARACTERS_PER_LINE

    if (textLines > banerTextLines) {
      banerTextLines = textLines
    }
  })

  const maxLines = Math.ceil(banerTextLines)
  const heightBanner = `${maxLines * MINIMUM_HEIGHT}px`

  return {
    heightBanner,
  }
}
