import React, { useEffect, useState } from 'react'
import { Box, Flex, HStack, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import {
  ContentstackArticleCard as ContentstackArticleCardProps,
  ContentstackNewCertonaSlider as ContentstackNewCertonaSliderProps,
} from '@Types/contentstack'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from 'composable/components/carousel'
import { ContentstackArticleCard } from 'composable/components/contentstack/article-card'
import { UiContainer } from 'composable/components/ui-container'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { GetRefinedCertonaData, formatDataItems, sliderDataPropsI, slidersBreakpoints } from './utils'
import { useAtgUser } from '../../../contexts'

declare let window: any

const CarouselNavigationIcons = ({ slider }) => (
  <>
    <Flex justifyContent="start" mb={4} hideBelow={'sm'}>
      <CarouselIconButton
        width={12}
        height={12}
        background="shading.100"
        borderRadius="var(--radii-base,4px)"
        ml={3}
        onClick={() => slider.current?.prev()}
        icon={<IoChevronBackOutline />}
        aria-label="Previous Slide"
        position="absolute"
        top={{ base: '35%', md: '40%' }}
        left="0"
        zIndex={2}
      />
    </Flex>
    <Flex justifyContent="end" mb={4} hideBelow={'sm'}>
      <CarouselIconButton
        width={12}
        height={12}
        background="shading.100"
        borderRadius="var(--radii-base,4px)"
        onClick={() => slider.current?.next()}
        icon={<IoChevronForwardOutline />}
        aria-label="Next Slide"
        position="absolute"
        top={{ base: '35%', md: '40%' }}
        zIndex={2}
        mr={-3}
      />
    </Flex>
  </>
)

interface CarouselNavigationDotsProps {
  currentSlide: number
  slider: any
  items: Array<ContentstackArticleCardProps>
}

const CarouselNavigationDots = ({ slider, items, currentSlide }: CarouselNavigationDotsProps) => (
  <HStack position="relative" width="full" justify="center" display={'flex'} gap={0}>
    {items.map(({ uid }, idx) => (
      <Box
        flexBasis={'100%'}
        w="0"
        tabIndex={0}
        aria-label={`Go to category ${idx + 1}`}
        cursor="pointer"
        key={uid}
        height={0 - 5}
        bg={currentSlide === idx ? 'text' : 'shading.300'}
        onClick={() => slider.current?.moveToIdx(idx)}
        onKeyDown={() => slider.current?.moveToIdx(idx)}
      />
    ))}
  </HStack>
)

export const NewCertonaSlider = (contantStack: ContentstackNewCertonaSliderProps) => {
  const { userSessionData } = useAtgUser()
  const countryCode = userSessionData?.dxlCountry
  const currencyCode = userSessionData?.dxlCurrency === 'USD' ? '$' : userSessionData?.dxlCurrency

  const [sliderDataArr, setSliderDataArr] = useState([])
  const [headingObj, setHeadingObj] = useState({})
  const [certonaRecommendationsData, setCertonaRecommendationsData] = useState(
    contantStack?.certonaRes ? contantStack.certonaRes : null,
  )

  const displayImages = true
  const [currentSlide, setCurrentSlide] = useState(0)
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: true,
    mode: 'free',
    ...slidersBreakpoints[displayImages.toString()],
  })
  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    if (userSessionData?.dxlCountry) {
      const certonaRecommendations = (data) => {
        setCertonaRecommendationsData(data)
        if (data) {
          createSchemeObj(contantStack)
          if (countryCode == 'US') {
            data.resonance = GetRefinedCertonaData(data.resonance)
            if (data.resonance) {
              createCertonaSlider(data.resonance.schemes)
            }
          } else {
            try {
              atgAxiosInstance.post('dxlproducts/recommendations', data.resonance).then((response: any) => {
                createCertonaSlider(response.data.schemes)
              })
            } catch (err) {
              console.log('Error in recommendations ', err)
            }
          }
        }
      }
      if (certonaRecommendationsData) {
        certonaRecommendations(certonaRecommendationsData)
      } else {
        if (!window?._certonaHandlers) {
          window._certonaHandlers = []
        }
        window.addCertonaHandler = (handler) => {
          window._certonaHandlers.push(handler)
        }
        window.addCertonaHandler(certonaRecommendations)
        window.certonaRecommendations = (data) => {
          window._certonaHandlers.forEach((fn) => fn(data))
        }
      }

      return () => {
        window.certonaRecommendations = null
      }
    }
  }, [userSessionData?.dxlCountry])

  const showSlideBtn = (items) => {
    return items?.length > (isMobile ? 1 : 5)
  }

  const createCertonaSlider = (dataArr: sliderDataPropsI[]) => {
    if (dataArr && dataArr.length > 0) {
      for (const element of dataArr) {
        let data = { ...element }
        if (contantStack.page_scheme == element.scheme) {
          ;(data['heading'] = data['heading'] ? data['heading'] : headingObj[data['scheme']]),
            (data['sliderData'] = formatDataItems(data['items']))
          setSliderDataArr([data])
        }
      }
    } else {
      setSliderDataArr([])
    }
  }

  const createSchemeObj = (data) => {
    if (data.eyebrow) {
      headingObj[data.page_scheme] = data.eyebrow
      setHeadingObj({ headingObj })
    }
  }

  if (sliderDataArr?.length > 0) {
    return (
      <>
        {sliderDataArr?.map(
          (item) =>
            item.display == 'yes' &&
            item.heading && (
              <UiContainer
                width={contantStack?.container_size}
                mx="auto"
                py={{ base: 4, md: 6 }}
                px={{ base: 0, md: 6 }}
                position="relative"
                marginTop={contantStack?.container_margin_top}
                marginBottom={contantStack?.container_margin_bottom}
                key={item.scheme}
              >
                <Heading py={4} textAlign={contantStack?.text_align} size="md">
                  {item?.heading}
                </Heading>

                {showSlideBtn(item?.sliderData) && <CarouselNavigationIcons slider={slider} />}

                <Carousel mb={4} ref={ref}>
                  {item?.sliderData?.map((obj) => (
                    <CarouselSlide key={`certona_slide_${obj.uid}`}>
                      <ContentstackArticleCard
                        text_decoration="underline"
                        article_card_title_font_size="100"
                        text_align="center"
                        {...obj}
                      />

                      {obj?.priceInfo?.type == 'standard' && (
                        <Text textAlign="center" fontWeight={'600'}>
                          {currencyCode}
                          {obj?.priceInfo?.upperbound}
                        </Text>
                      )}

                      {obj?.priceInfo?.type == 'sale' && (
                        <Box display="flex" alignItems="center">
                          <Text>
                            <del>
                              {currencyCode}
                              {obj?.priceInfo?.upperbound}
                            </del>
                          </Text>
                          <Text fontWeight={'600'} margin={[0, 4]}>
                            {currencyCode}
                            {obj?.priceInfo?.lowerbound}
                          </Text>
                          <Text fontWeight={'600'} color={'red'}>
                            Save {obj?.priceInfo?.discountPercent}%{' '}
                          </Text>
                        </Box>
                      )}

                      {obj?.priceInfo?.type == 'range' && (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Text display="inline-block" fontWeight={'600'} marginRight={1}>
                            {currencyCode}
                            {obj?.priceInfo?.lowerbound} - {currencyCode}
                            {obj?.priceInfo?.upperbound}
                          </Text>
                          <Text fontWeight={'600'} color={'red'} textAlign="center">
                            Save Up To &nbsp; {obj?.priceInfo?.discountPercent}%
                          </Text>
                        </Box>
                      )}
                    </CarouselSlide>
                  ))}
                </Carousel>

                {showSlideBtn(item?.sliderData) && (
                  <CarouselNavigationDots currentSlide={currentSlide} items={item?.sliderData} slider={slider} />
                )}
              </UiContainer>
            ),
        )}
      </>
    )
  }
}
