import { useEffect } from 'react'

export const Scene7VideoViewer = ({ videoUrl, startTime }) => {
  useEffect(() => {
    const video = document.createElement('video')
    video.src = videoUrl
    video.controls = true

    video.currentTime = startTime

    const container = document.getElementById('scene7-video-container')
    container?.appendChild(video)

    return () => {
      container?.removeChild(video)
    }
  }, [videoUrl, startTime])

  return <div id="scene7-video-container"></div>
}
