import { ContentstackPageContainer as ContentstackPageContainerProps } from '@Types/contentstack'
import { UiContainer } from 'composable/components/ui-container'
import { ContentItemByType } from './content-item-by-type'

interface PageContentItemsProps {
  content: Array<ContentstackPageContainerProps['page_content'][number]>
}

export const ContentList = ({ content }: PageContentItemsProps) => (
  <>
    {content?.map((contentItem) => {
      if (contentItem === null) return null

      return (
        <UiContainer
          key={contentItem.uid}
          size={contentItem.container_size}
          marginTop={contentItem.container_margin_top}
          marginBottom={contentItem.container_margin_bottom}
        >
          <ContentItemByType content={contentItem} />
        </UiContainer>
      )
    })}
  </>
)
