import { VStack } from '@chakra-ui/react'
import { SidebarGenericItem } from './sidebar-generic-item'

export interface SideBarNavMenuGenericProps {
  activeItem?: any
  activeItemIdx: number
  items: any[]
  setActiveIdx: (number) => void
}

export const SideBarNavMenuGeneric = ({
  activeItem,
  activeItemIdx,
  setActiveIdx,
  items,
}: SideBarNavMenuGenericProps) => {
  return (
    <VStack gap={2} alignItems={'flex-start'}>
      {items.map((item, idx) => {
        return (
          <SidebarGenericItem
            key={item?.title}
            label={item?.title}
            href={`#${item.slug}`}
            state={activeItem === idx ? 'Active' : 'Default'}
            rootProps={{
              height: '44px',
              py: 2.5,
              px: 0,
              _hover: {
                background: 'transparent',
              },
              ...(item.otherProps ?? {}),
            }}
            onClick={() => setActiveIdx(idx)}
          />
        )
      })}
    </VStack>
  )
}
