import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Center,
  Select,
  Box,
  Divider,
  Checkbox,
  HStack,
  ModalOverlay,
} from '@chakra-ui/react'
import { CountryIcon } from 'atg/icons/country-icon'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'

const REGIONS = [
  { title: 'North America', value: 'North_America' },
  { title: 'South America', value: 'South_America' },
  { title: 'Asia-Pacific', value: 'Asia-Pacific' },
  { title: 'Europe', value: 'Europe' },
  { title: 'Middle East', value: 'Middle_East' },
  { title: 'Africa', value: 'Africa' },
]

const CountrySelector = () => {
  const searchParams = useSearchParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currencyCodes, setCurrencyCodes] = useState([])
  const [countryData, setCountryData] = useState<any>([])
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const { formatMessage } = useFormat({ name: 'common' })
  const [preferenceChecked, setPreferenceChecked] = React.useState(true)
  const { userSessionData, fetchUserSessionData } = useAtgUser()
  const { refreshCart } = useAtgLegacyCart()
  const [headerRes, setHeaderRes] = useState<any>(userSessionData)
  const router = useRouter()
  useEffect(() => {
    const intl = searchParams.get('intl')
    if (intl == 'y') {
      handleModelOpen()
    }
  }, [])

  useEffect(() => {
    setFooterDefaultValues()
  }, [headerRes])

  useEffect(() => {
    setHeaderRes(userSessionData)
  }, [userSessionData])

  const getAtgHeaderData = async () => {
    fetchUserSessionData()
  }

  const setFooterDefaultValues = () => {
    setSelectedRegion(headerRes?.dxlRegion)
    setSelectedCountry(headerRes?.dxlCountry)
    setSelectedCurrency(headerRes?.dxlCurrency)
  }

  const getCountryList = async (region: string) => {
    const res = await atgAxiosInstance.get('fiftyone/countries/' + region).then((res: any) => {
      const countryDataArr = res.data.countryData
      setCountryData(res.data.countryData)
      setCurrency(res.data.countryData)
      if (countryDataArr.length > 0) {
        const flAr = countryDataArr.filter((obj) => {
          return obj.countryCode == res.data.selectedCountry.countryCode
        })
        if (flAr.length > 0) {
          setSelectedCountry(res.data.selectedCountry.countryCode)
          setSelectedCurrency(res.data.selectedCountry?.currencyCode)
        }
      }
    })
  }

  const handleRegionSelect = (event: any) => {
    if (event.target.value) {
      setSelectedRegion(event.target.value)
      setSelectedCountry('')
      setSelectedCurrency('')
      getCountryList(event.target.value)
    } else {
      setCountryData([])
      setCurrencyCodes([])
      setSelectedRegion('')
      setSelectedCountry('')
      setSelectedCurrency('')
    }
  }

  const handleModelOpen = () => {
    onOpen()
    if (!!countryData) {
      getCountryList(selectedRegion)
    }
  }

  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value)
    if (event.target.value) {
      const selectedCountryObj = countryData.find((item: any) => item.countryCode === event.target.value)
      setSelectedCurrency(selectedCountryObj.currencyCode)
    } else {
      setSelectedCurrency('')
    }
  }

  const handleCurrencySelect = (event) => {
    setSelectedCurrency(event.target.value)
  }

  const renderRegionSelect = () => {
    return (
      <Select
        display={'flex'}
        flexDirection={'column'}
        gap={3}
        marginBottom={4}
        value={selectedRegion}
        placeholder="Select your region"
        onChange={handleRegionSelect}
      >
        {REGIONS.map((region: any, index: number) => (
          <option key={`region-list-${index}`} value={region.value}>
            {region.title}
          </option>
        ))}
      </Select>
    )
  }

  const renderCountrySelect = () => {
    return (
      <Select marginBottom={4} placeholder="Select your country" value={selectedCountry} onChange={handleCountrySelect}>
        {countryData?.map((country: any, index: number) => (
          <option key={`country-list-${index}`} value={country.countryCode}>
            {country.countryName}
          </option>
        ))}
      </Select>
    )
  }

  const renderCurrencySelect = () => {
    return (
      <Select
        marginBottom={4}
        placeholder="Select your currency"
        value={selectedCurrency}
        onChange={handleCurrencySelect}
      >
        {currencyCodes.map((currency: any, index: number) => (
          <option key={`currency-list-${index}`} value={currency}>
            {currency}
          </option>
        ))}
      </Select>
    )
  }

  const setCurrency = (countryList: []) => {
    let codes = currencyCodes
    countryList?.map((currentValue: any) => {
      if (!(codes.indexOf(currentValue.currencyCode) > -1)) {
        codes.push(currentValue?.currencyCode)
      }
    })
    setCurrencyCodes(codes)
  }

  const handleSubmit = async () => {
    try {
      await atgAxiosInstance.post('fiftyone/updateCountry', {
        countryCode: selectedCountry,
        currencyCode: selectedCurrency,
        rememberMyPreference: preferenceChecked,
      })

      getAtgHeaderData()
      refreshCart()
      const { query } = router
      if (query['e4x_auto']) {
        const updatedQuery = { ...query }
        delete updatedQuery['e4x_auto']

        router.replace(
          {
            pathname: router.pathname,
            query: updatedQuery,
          },
          undefined,
          { shallow: true },
        )
      }
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleUSUserSubmit = async () => {
    try {
      await atgAxiosInstance.post('fiftyone/updateCountry', {
        countryCode: 'US',
        currencyCode: 'USD',
        rememberMyPreference: preferenceChecked,
      })

      getAtgHeaderData()
      refreshCart()
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const isFormInvalid = () => {
    return !selectedRegion || !selectedCountry || !selectedCurrency
  }

  return (
    <>
      {headerRes && (
        <Box>
          <Button
            size={'md'}
            bgColor={'transparent'}
            onClick={handleModelOpen}
            width="100%"
            variant="outline"
            borderRadius="5"
            alignSelf="center"
          >
            <HStack>
              {headerRes && <CountryIcon country={headerRes?.dxlCountry} />}
              <Text>
                {headerRes?.countryName} ({headerRes?.dxlCurrency})
              </Text>
            </HStack>
          </Button>
        </Box>
      )}

      <Modal closeOnOverlayClick={false} size="xs" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={'512px'}
          borderRadius={8}
          height={{ base: 'calc(100% - 32px)', md: 'auto' }}
          m={{ base: 4, md: 'auto' }}
        >
          <ModalHeader
            p={4}
            gap={4}
            borderBottom={'solid 1px'}
            borderBottomColor={'var(--semantic-surface-highlight, #E1E5E8)'}
          >
            <Text textStyle={'heading-desktop-200'}>
              {formatMessage({
                id: 'text.currencySelector.InternationalShipping',
              })}
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody px={5} py={6} gap={5}>
            <Box mt={0}>
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Text textStyle={'heading-desktop-75'}>
                  {formatMessage({
                    id: 'label.currencySelector.region',
                  })}
                </Text>
                {renderRegionSelect()}
              </Box>

              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Text textStyle={'heading-desktop-75'}>
                  {formatMessage({
                    id: 'label.currencySelector.country',
                  })}
                </Text>
                {renderCountrySelect()}
              </Box>

              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Text textStyle={'heading-desktop-75'}>
                  {formatMessage({
                    id: 'label.currencySelector.currency',
                  })}
                </Text>
                {renderCurrencySelect()}
              </Box>
              <Center>
                <Box width="100%" mb={4}>
                  <Button
                    isDisabled={isFormInvalid()}
                    width="100%"
                    borderRadius="5"
                    alignSelf={'center'}
                    onClick={handleSubmit}
                  >
                    <Text textStyle={'ui-button-100'}>
                      {formatMessage({
                        id: 'cta.currencySelector.saveAndContinue',
                      })}
                    </Text>
                  </Button>
                </Box>
              </Center>
              <Center>
                <Box width="100%" mb={4}>
                  <Button
                    width="100%"
                    variant="outline"
                    borderRadius="5"
                    alignSelf={'center'}
                    onClick={handleUSUserSubmit}
                  >
                    <Text textStyle={'semantic-brand-primary'} textAlign="center">
                      {formatMessage({
                        id: 'cta.currencySelector.proceedAsUscustomer',
                      })}
                    </Text>
                  </Button>
                </Box>
              </Center>
              <Box mb={4}>
                <Checkbox onChange={(e) => setPreferenceChecked(e.target.checked)} colorScheme="black" defaultChecked>
                  <Text textStyle={'body-75'}>
                    {formatMessage({
                      id: 'checkbox.currencySelector.preference',
                    })}
                  </Text>
                </Checkbox>
              </Box>
              <Divider color={'semantic-surface-border'} />
              <Box pt={5}>
                <Text mb={4} textStyle={'body-75'}>
                  {formatMessage({
                    id: 'text.currencySelector.footerText1',
                  })}
                </Text>
                <Text textStyle={'body-75'}>
                  {formatMessage({
                    id: 'text.currencySelector.footerText2',
                  })}
                </Text>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CountrySelector
