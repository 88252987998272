import { ContentstackVideoCard as ContentstackVideoCardProps } from '@Types/contentstack'
import { DXLVideo } from '../video-card/index'

export const ContentstackVideoCard = (items: ContentstackVideoCardProps) => {
  const title = items.title || ''
  const link = items.link || { href: '', title: '' }
  const video_title = items.video_title || ''
  const video_type = items.video_type || 'Other'
  const container_alignment = items.container_alignment || ''

  if (!items?.link?.href) return <></>
  return (
    <DXLVideo
      title={title}
      link={link}
      video_title={video_title}
      video_type={video_type}
      container_alignment={container_alignment}
      analyticsTrackingData={items.analyticsTrackingData}
    />
  )
}
