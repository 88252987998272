import { FooterBanner } from 'composable/components/footer-banner'
import { UiContainer } from 'composable/components/ui-container'

const FooterBannerTastic: React.FC<any> = ({ items, analyticsTrackingData }) => {
  return (
    <UiContainer p={0}>
      <FooterBanner analyticsTrackingData={analyticsTrackingData} items={items} />
    </UiContainer>
  )
}
export default FooterBannerTastic
