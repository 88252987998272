import { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import { ContentstackBannerAnnouncement as ContentstackBannerAnnouncementProps } from '@Types/contentstack'
import { BannerAnnouncement } from 'composable/components/banner-announcement'
import { IoCloseOutline } from 'react-icons/io5'
import { useCalculateHeightBanner } from '../hooks/useCalculateHeightBanner'
import useContentstack, { BAEntry } from '../hooks/useContentstack'

export const BANNERANNOUNCE_ITEM = 'banner_announcement_items'

export const ContentstackBannerAnnouncement = ({
  children,
  content_alignment,
  background_color,
  text_color,
  analyticsTrackingData,
  monetateBanner,
}: ContentstackBannerAnnouncementProps) => {
  const [contentList, setContentList] = useState<BAEntry[]>()
  const [show, setShow] = useState(true)

  const { getEntryByUid, isLoading } = useContentstack()
  const { heightBanner } = useCalculateHeightBanner(contentList)

  useEffect(() => {
    getBannerAnnounceContent(children)
  }, [])

  const getBannerAnnounceContent = async (children) => {
    try {
      const itemsResults = children?.map((item) => getEntryByUid(BANNERANNOUNCE_ITEM, item.uid))
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Footer data', err)
    }
  }

  return (
    <Box
      minH={'60px'}
      display={show ? 'flex' : 'none'}
      alignItems={'center'}
      justifyContent={content_alignment}
      bg={background_color.hex}
      py={{ base: 0, md: 3 }}
      px={{ base: 0, md: 0 }}
      margin={{ base: '0 -1rem', md: 'auto' }}
    >
      <Skeleton isLoaded={!(isLoading || !contentList)} width={'100%'}>
        <BannerAnnouncement
          items={contentList}
          analyticsTrackingData={analyticsTrackingData}
          monetateBanner={monetateBanner}
          text_color={text_color}
          content_alignment={content_alignment}
        />
      </Skeleton>

      {!monetateBanner && (
        <Box
          display={'flex'}
          position={'absolute'}
          right={{ base: 0, md: 2 }}
          mt={{ base: '0', md: 'auto' }}
          tabIndex={0}
          zIndex={1}
          aria-label="Close announcement"
          onClick={() => setShow(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(false)
            }
          }}
          color={text_color.hex}
          cursor={'pointer'}
        >
          <IoCloseOutline cursor={'pointer'} fontSize={'x-large'} />
        </Box>
      )}
    </Box>
  )
}
