import { ContentstackCertonaSlider as ContentstackCertonaSliderProps } from '@Types/contentstack'

import { CertonaSlider } from 'frontastic/tastics/composable/certona-slider'

export const COMPONENT_CERTONA = 'certona_slider'

export const ContentstackCertonaSlider = (data: ContentstackCertonaSliderProps) => {
  if (data) {
    return <CertonaSlider {...data} />
  }
}
