import { ContentstackNewCertonaSlider as ContentstackNewCertonaSliderProps } from '@Types/contentstack'

import { NewCertonaSlider } from 'frontastic/tastics/composable/new-certona-slider'

export const COMPONENT_NEW_CERTONA = 'component_certona_slider'

export const ContentstackNewCertonaSlider = (data: ContentstackNewCertonaSliderProps) => {
  if (data) {
    return <NewCertonaSlider {...data} />
  }
}
