import NextLink from 'next/link'
import { AspectRatio, Box, BoxProps, Button, ButtonProps, LinkBox, LinkOverlay, Image } from '@chakra-ui/react'

export interface ProductCardProps {
  root?: Omit<BoxProps, 'children'>
  topLeft?: BoxProps
  image?: {
    src?: string
    alt?: string
    ratio?: number
    sizes?: string
    objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  }
  brand?: BoxProps
  name?: BoxProps
  href?: string
  price?: BoxProps
  button?: ButtonProps
}

export const ProductCard = ({ root, topLeft, image, brand, name, href, price, button }: ProductCardProps) => {
  return (
    <LinkBox display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" {...root}>
      <LinkOverlay as={NextLink} href={href ?? ''}>
        {image?.src && (
          <AspectRatio
            _hover={{ cursor: 'pointer' }}
            ratio={image?.ratio ?? 1}
            position="relative"
            width={288}
            overflow="hidden"
            mb={3}
          >
            <Image
              _hover={{ cursor: 'pointer' }}
              src={image?.src ?? ''}
              alt={image?.alt ?? ''}
              style={{ objectFit: image.objectFit || 'contain' }}
            />
          </AspectRatio>
        )}
      </LinkOverlay>
      {topLeft?.children && <Box position="absolute" top="0" left="0" {...topLeft} />}
      {brand?.children && (
        <Box
          textStyle={['callouts-blockquote-75-XS', null, 'callouts-blockquote-50']}
          textColor="text-muted"
          {...brand}
        />
      )}
      {name?.children && (
        <Box textStyle={['callouts-blockquote-75-Default', null, 'callouts-blockquote-100']} textColor="text" my={1}>
          {name.children}
        </Box>
      )}
      {price?.children && (
        <Box textStyle={['callouts-blockquote-50', null, 'callouts-blockquote-75']} textColor="text" {...price} />
      )}
      {button?.children && <Button mt={3} size="md" width="full" {...button} />}
    </LinkBox>
  )
}
