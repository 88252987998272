export interface itemsI {
  Title: string
  Type: string
  Detail_URL: string
  Image_URL: string
  Price?: string
  Upperbound: any
  Lowerbound: any
  DiscountPercent?: any
}

export interface sliderDataPropsI {
  heading?: string
  display: string
  scheme: string
  items: itemsI[]
}

export const slidersBreakpoints = {
  true: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 6,
          spacing: 16,
        },
      },
    },
  },
  false: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 5.5,
          spacing: 16,
        },
      },
    },
  },
}

export const GetRefinedCertonaData = (certonaObject) => {
  for (let i = 0; i < certonaObject.schemes.length; i++) {
    if (certonaObject.schemes[i].items.length) {
      for (let j = 0; j < certonaObject.schemes[i].items.length; j++) {
        if (certonaObject.schemes[i].items[j].SalePrice.indexOf('-') > 0) {
          certonaObject.schemes[i].items[j]['type'] = 'range'
          let tempSalePrice = certonaObject.schemes[i].items[j].SalePrice.split('-')
          if (tempSalePrice[0].indexOf(',') > 0) {
            certonaObject.schemes[i].items[j]['lowerbound'] = tempSalePrice[0].replace(',', '')
            certonaObject.schemes[i].items[j]['lowerbound'] = certonaObject.schemes[i].items[j]['lowerbound'].replace(
              '$',
              '',
            )
          } else {
            certonaObject.schemes[i].items[j]['lowerbound'] = tempSalePrice[0].replace('$', '')
          }
        } else if (certonaObject.schemes[i].items[j].SalePrice.indexOf(',') > 0) {
          certonaObject.schemes[i].items[j]['lowerbound'] = certonaObject.schemes[i].items[j].SalePrice.replace(',', '')
          certonaObject.schemes[i].items[j]['lowerbound'] = certonaObject.schemes[i].items[j]['lowerbound'].replace(
            '$',
            '',
          )
        } else {
          certonaObject.schemes[i].items[j]['lowerbound'] = certonaObject.schemes[i].items[j].SalePrice.replace('$', '')
        }
        if (certonaObject.schemes[i].items[j].Price.indexOf(',') > 0) {
          certonaObject.schemes[i].items[j]['upperbound'] = certonaObject.schemes[i].items[j].Price.replace(',', '')
          certonaObject.schemes[i].items[j]['upperbound'] = certonaObject.schemes[i].items[j]['upperbound'].replace(
            '$',
            '',
          )
        } else {
          certonaObject.schemes[i].items[j]['upperbound'] = certonaObject.schemes[i].items[j].Price.replace('$', '')
        }
        if (certonaObject.schemes[i].items[j].SalePrice.indexOf('-') === -1) {
          if (certonaObject.schemes[i].items[j]['upperbound'] === certonaObject.schemes[i].items[j]['lowerbound']) {
            certonaObject.schemes[i].items[j]['type'] = 'standard'
          } else {
            certonaObject.schemes[i].items[j]['type'] = 'sale'
            let discountPercent = Math.round(
              100 -
                (certonaObject.schemes[i].items[j]['lowerbound'] / certonaObject.schemes[i].items[j]['upperbound']) *
                  100,
            )
            if (discountPercent > 0) {
              certonaObject.schemes[i].items[j]['discountPercent'] = +discountPercent
            }
          }
        } else {
          let discountPercent = Math.round(
            100 -
              (certonaObject.schemes[i].items[j]['lowerbound'] / certonaObject.schemes[i].items[j]['upperbound']) * 100,
          )
          if (discountPercent > 0) {
            certonaObject.schemes[i].items[j]['discountPercent'] = +discountPercent
          }
        }
        if (certonaObject.schemes[i].items[j].SalePrice.indexOf('-') > 0) {
          let tempSalePrice = certonaObject.schemes[i].items[j].SalePrice.split('-')
          certonaObject.schemes[i].items[j]['lowerbound'] = tempSalePrice[0].replace('$', '')
        } else {
          certonaObject.schemes[i].items[j]['lowerbound'] = certonaObject.schemes[i].items[j].SalePrice.replace('$', '')
        }
        certonaObject.schemes[i].items[j]['upperbound'] = certonaObject.schemes[i].items[j].Price.replace('$', '')
      }
    }
  }
  return certonaObject
}

export const formatDataItems = (items: itemsI[]) => {
  const itemsList = items.map((obj: any, index) => {
    let newJson = {
      uid: `slidebtn${index}`,
      url: obj?.Detail_URL ? obj?.Detail_URL : obj?.detail_URL,
      image: {
        url: obj?.Image_URL ? obj?.Image_URL : obj?.image_URL,
      },
      article_card_title: obj?.Title ? obj?.Title : obj?.title,
      priceInfo: {
        price: obj?.Price ? obj?.Price : obj?.price,
        type: obj?.Type ? obj.Type : obj.type,
        salePrice: obj?.SalePrice ? obj.SalePrice : obj.salePrice,
        upperbound: obj?.Upperbound ? obj.Upperbound : obj.upperbound,
        lowerbound: obj.Lowerbound ? obj.Lowerbound : obj.lowerbound,
        discountPercent: obj.DiscountPercent ? obj.DiscountPercent : obj.discountPercent,
      },
    }
    return newJson
  })
  return itemsList
}
