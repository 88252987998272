import React, { useEffect, useState } from 'react'
import { IconProps, createIcon } from '@chakra-ui/react'

interface CountryIconProps {
  country: string
}

export const CountryIcon: React.FC<IconProps & CountryIconProps> = ({ country, ...props }) => {
  const [countryIconData, setCountryIconData] = useState<string | null>(null)

  useEffect(() => {
    const fetchSVG = async () => {
      try {
        const dynamicPath = `/icons/flags/${country}.svg`
        const response = await fetch(dynamicPath)
        const svgData = await response.text()
        setCountryIconData(svgData)
      } catch (error) {
        console.error('Error fetching SVG:', error)
      }
    }

    fetchSVG()
  }, [country])

  const CountrySVG = createIcon({
    displayName: 'CountryIcon',
    viewBox: '0 0 40 40',
    path: <g dangerouslySetInnerHTML={{ __html: countryIconData || '' }} />,
  })

  return <CountrySVG borderRadius="50%" height={25} width={25} />
}
