import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Button, Container, Flex, HStack, Text } from '@chakra-ui/react'
import { Product } from '@Types/product/Product'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { getProductsByIds } from 'frontastic/actions/product'
import { useComposable } from '../composable-provider'
import { Price } from '../price'
import { ProductCard } from '../product-card'

export interface GenericConnectorProps {
  title?: string
  cta_label?: string
  cta_href?: string
  cta_height?: string
  cta_max_width?: string
  cta_min_width?: string
  products?: {
    name: string
    slug: string
    brand?: string
    img?: {
      url?: string
      alt?: string
    }
    price?: PriceProps
  }[]
}

export interface PriceProps {
  current: number
  currentFormatted: string
  regular?: number
  regularFormatted?: string
}

export const ContentstackGenericConnector = (props: GenericConnectorProps) => {
  const [productList, setProductList] = useState([])
  const { title, cta_label, cta_href, products, cta_height, cta_max_width, cta_min_width } = props
  const { path } = useComposable()
  const router = useRouter()

  useEffect(() => {
    fetchProducts(products)
  }, [])

  const fetchProducts = async (products) => {
    const productIds = products?.data?.map((product) => product.id)
    const productListResutls = await getProductsByIds(productIds)

    const productListMapped = productListResutls?.map((item: Product) => {
      const firstVariant = item.variants[0]
      return {
        href: `/p/${item.slug}`,
        imageUrl: firstVariant.images[0] ?? '',
        brand: firstVariant?.attributes?.brand ?? '',
        title: item.name ?? '',
        price: firstVariant.price,
      }
    })
    setProductList(productListMapped)
  }

  if (!products || !productList) return null

  return (
    <>
      <Container maxW="container.xl" px={{ base: 'sm', md: 'xl' }} py={{ base: 'sm', md: 'lg' }}>
        <Flex gap={{ base: '0.5rem', md: '0.625rem' }} mb={{ base: '8', md: '12' }} justifyContent={'space-between'}>
          <Text alignSelf={'flex-end'} textStyle={{ base: 'Body-L', md: 'Body-XL' }} color={'primary'}>
            {title}
          </Text>
          {cta_label && cta_href && <Button onClick={() => router.push(cta_href)}>{cta_label}</Button>}
        </Flex>

        <Flex alignItems={'baseline'} wrap={'wrap'} justifyContent={'space-evenly'} gap={2}>
          {productList?.map((product, idx: number) => (
            <ProductCard
              key={idx}
              brand={{
                children: <Text>{product.brand}</Text>,
              }}
              name={{
                children: (
                  <Text fontSize={'xxs'} fontWeight={'semibold'} lineHeight={'4'}>
                    {product.title}
                  </Text>
                ),
              }}
              image={{
                src: product.imageUrl || '',
                alt: product.imgageAlt,
                ratio: 1,
              }}
              href={product.href}
              price={{
                children: <Price price={CurrencyHelpers.formatForCurrency(product?.price?.centAmount)} />,
              }}
              root={{
                flex: 1,
              }}
            />
          ))}
        </Flex>
      </Container>
    </>
  )
}
