import { AspectRatio, Box } from '@chakra-ui/react'
import { Scene7VideoViewer } from './Scene7VideoViewer'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

interface VideoCardProps {
  title: string | null
  link: {
    href: string
    title: string
  }
  video_title: string | null
  video_type: 'Scene7' | 'Other'
  container_alignment: string
  analyticsTrackingData?: string
}

export const DXLVideo = ({
  title,
  link,
  video_title,
  video_type,
  container_alignment,
  analyticsTrackingData,
}: VideoCardProps) => {
  return video_type === 'Other' ? (
    <Box
      onClick={() => {
        promoClickUtagLinkEvent(analyticsTrackingData)
      }}
    >
      <AspectRatio width={'100%'}>
        <iframe title={title} src={link.href} allowFullScreen />
      </AspectRatio>
    </Box>
  ) : (
    <Box
      onClick={() => {
        promoClickUtagLinkEvent(analyticsTrackingData)
      }}
    >
      <AspectRatio width={'100%'}>
        <Scene7VideoViewer videoUrl={link.href} startTime={0.11}></Scene7VideoViewer>
      </AspectRatio>
    </Box>
  )
}
