import { usePathname } from 'next/navigation'
import ContentstackEntryTastic from 'frontastic/tastics/composable/contentstack-entry'
import { useComposable } from '../composable-provider'

export interface PageHeaderProps {
  meta_title?: string
  content?: [any]
  slug?: string
  containerSize?: string
  containerMarginBottom?: string
  containerMarginTop?: string
}

export const PageHeader = (data: PageHeaderProps) => {
  const { path } = useComposable()
  const pathname = usePathname()

  const fullPLPPath = path.getPLP(data?.slug)
  if (fullPLPPath !== pathname) {
    console.error('PageHeader: slug does not match pathname')
    return
  }

  return ContentstackEntryTastic({
    data: {
      entryData: {
        dataSource: {
          ...data.content[0],
          content_type: data.content[0]._content_type_uid,
        },
      },
    },
  })
}
