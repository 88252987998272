import { useState } from 'react'
import NextLink from 'next/link'
import { Box, Button, ButtonProps, Center, Circle, HStack } from '@chakra-ui/react'
import { Money } from '@Types/product/Money'
import { fontFamilies, sizes } from 'composable/chakra/figma-tokens'
import { Price } from 'composable/components/price'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../carousel'
import { calculateFontStyles } from '../contentstack/utils'
import { ProductCard } from '../product-card'

type ProductSliderVariant = 'default' | 'large'

export interface ProductSlider {
  href: string
  imageUrl: string
  brand: string
  title: string
  price: Money
}
export interface ProductSliderProps {
  showDots?: boolean
  title?: string
  subtitle?: string
  variant?: ProductSliderVariant
  callToAction?: ButtonProps & { ctaLink?: string }
  productList?: Array<ProductSlider>
  containerEyebrow?: string
  containerTitle?: string
  containerDescription?: string
  headingAlignment?: string
  titleFontFamily?: string
  titleFontSize?: number
}

export const ProductSlider = ({
  showDots = false,
  title,
  subtitle,
  variant = 'default',
  callToAction,
  productList,
  containerEyebrow,
  containerTitle,
  containerDescription,
  headingAlignment,
  titleFontFamily,
  titleFontSize,
}: ProductSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: true,
    mode: 'free',
    slides: {
      origin: 0,
      perView: 1,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 720px)': {
        slides: {
          origin: 0,
          perView: 2,
          spacing: 8,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          origin: 0,
          perView: 3,
          spacing: 8,
        },
      },
      '(min-width: 1244px)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 8,
        },
      },
    },
  })

  const alignment: any = headingAlignment || 'center'
  const fontSize: any = titleFontSize || 700

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(fontSize)

  if (!productList?.length) {
    return null
  }

  return (
    <Box py={10} maxW="container.2xl" mx="auto">
      {containerEyebrow && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="14px"
          fontWeight={700}
          lineHeight="14px"
          letterSpacing="0.04em"
          textAlign={alignment}
          marginBottom={sizes[3]}
        >
          {containerEyebrow}
        </Box>
      )}
      {containerTitle && (
        <Box
          fontFamily={titleFontFamily || fontFamilies.primary}
          fontSize={_titleFontSize}
          fontWeight={titleFontWeight}
          lineHeight={titleLineHeight}
          textAlign={alignment}
          marginBottom={sizes[3]}
        >
          {containerTitle}
        </Box>
      )}
      {containerDescription && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="20px"
          fontWeight={400}
          lineHeight="24px"
          textAlign={alignment}
          marginBottom={sizes[9]}
        >
          {containerDescription}
        </Box>
      )}

      <Box display={'flex'} width="100%" alignItems={'center'}>
        <CarouselIconButton
          width="48px"
          height="48px"
          background="shading.100"
          borderRadius="4px"
          onClick={() => slider.current?.prev()}
          icon={<IoChevronBackOutline />}
          aria-label="Previous Slide"
        />
        <Carousel mb="48px" ref={ref} width={'100%'}>
          {productList.map((product) => {
            return (
              <CarouselSlide key={product.href}>
                <ProductCard
                  root={{
                    maxWidth: [
                      productSliderCardWidthMobile[variant],
                      null,
                      null,
                      productSliderCardWidthDesktop[variant],
                    ],
                  }}
                  href={product.href}
                  image={{
                    src: product.imageUrl,
                    alt: product.title,
                    objectFit: 'cover',
                    ratio: 3 / 4,
                  }}
                  name={{
                    children: product.title,
                  }}
                  brand={{
                    children: product.brand,
                  }}
                  price={{
                    children: <Price price={CurrencyHelpers.formatForCurrency(product?.price?.centAmount)} />,
                  }}
                />
              </CarouselSlide>
            )
          })}
        </Carousel>
        <CarouselIconButton
          width="48px"
          height="48px"
          background="shading.100"
          borderRadius="4px"
          onClick={() => slider.current?.next()}
          icon={<IoChevronForwardOutline />}
          aria-label="Next Slide"
        />
      </Box>
      {showDots && productList.length > 1 && (
        <HStack position="relative" width="full" justify="center">
          {productList.map((_, idx) => (
            <Circle
              tabIndex={0}
              aria-label={`Go to product ${idx + 1}`}
              cursor="pointer"
              key={idx}
              size="2"
              bg={currentSlide === idx ? 'text' : 'shading.300'}
              onClick={() => slider.current?.moveToIdx(idx)}
              onKeyPress={() => slider.current?.moveToIdx(idx)}
            />
          ))}
        </HStack>
      )}

      <Center>
        {callToAction?.children && (
          <NextLink href={callToAction?.ctaLink ?? '/'} passHref>
            <Button>{callToAction.children}</Button>
          </NextLink>
        )}
      </Center>
    </Box>
  )
}

const productSliderItemsMobile: Record<ProductSliderVariant, number> = {
  default: 2.2,
  large: 1.2,
}

const productSliderCardWidthMobile: Record<ProductSliderVariant, string> = {
  default: '158px',
  large: '260px',
}

const productSliderItemsDesktop: Record<ProductSliderVariant, number> = {
  default: 4,
  large: 3.2,
}

const productSliderCardWidthDesktop: Record<ProductSliderVariant, string> = {
  default: '290px',
  large: '390px',
}
