import { Icon } from '@chakra-ui/react'

const ApplePlayIcon = (props) => (
  <Icon viewBox="0 0 120 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="119" height="39" rx="6.5" stroke="#15191C" />
    <path
      d="M81.5257 19.201V21.492H80.0896V22.9944H81.5257V28.0994C81.5257 29.8426 82.3143 30.5398 84.2981 30.5398C84.6468 30.5398 84.9788 30.4983 85.2693 30.4485V28.9627C85.0203 28.9876 84.8626 29.0042 84.5887 29.0042C83.7005 29.0042 83.3104 28.5892 83.3104 27.6429V22.9944H85.2693V21.492H83.3104V19.201H81.5257Z"
      fill="#15191C"
    />
    <path
      d="M90.3232 30.6644C92.9628 30.6644 94.5815 28.8963 94.5815 25.9661C94.5815 23.0525 92.9545 21.2762 90.3232 21.2762C87.6835 21.2762 86.0566 23.0525 86.0566 25.9661C86.0566 28.8963 87.6752 30.6644 90.3232 30.6644ZM90.3232 29.0789C88.7709 29.0789 87.8994 27.9417 87.8994 25.9661C87.8994 24.0071 88.7709 22.8616 90.3232 22.8616C91.8671 22.8616 92.747 24.0071 92.747 25.9661C92.747 27.9334 91.8671 29.0789 90.3232 29.0789Z"
      fill="#15191C"
    />
    <path
      d="M95.9664 30.49H97.7511V25.1526C97.7511 23.8826 98.7056 23.0276 100.059 23.0276C100.374 23.0276 100.905 23.0857 101.055 23.1356V21.3758C100.864 21.326 100.524 21.3011 100.258 21.3011C99.0792 21.3011 98.0748 21.9485 97.8175 22.8367H97.6846V21.4505H95.9664V30.49Z"
      fill="#15191C"
    />
    <path
      d="M105.486 22.7952C106.806 22.7952 107.669 23.7166 107.711 25.136H103.145C103.245 23.7249 104.166 22.7952 105.486 22.7952ZM107.702 28.0496C107.37 28.7552 106.632 29.1453 105.552 29.1453C104.125 29.1453 103.203 28.1409 103.145 26.5555V26.4559H109.529V25.8333C109.529 22.9944 108.009 21.2762 105.494 21.2762C102.946 21.2762 101.327 23.1107 101.327 25.9993C101.327 28.888 102.913 30.6644 105.503 30.6644C107.57 30.6644 109.014 29.6683 109.421 28.0496H107.702Z"
      fill="#15191C"
    />
    <path
      d="M69.8221 27.1519C69.9598 29.3716 71.8095 30.7912 74.5626 30.7912C77.505 30.7912 79.3462 29.3028 79.3462 26.9282C79.3462 25.0612 78.2966 24.0288 75.7499 23.4352L74.382 23.0996C72.7645 22.7211 72.1106 22.2135 72.1106 21.3273C72.1106 20.2088 73.1259 19.4775 74.6487 19.4775C76.0941 19.4775 77.0921 20.1916 77.2727 21.3359H79.1483C79.0365 19.2452 77.1953 17.774 74.6745 17.774C71.9644 17.774 70.1576 19.2452 70.1576 21.4564C70.1576 23.2803 71.1815 24.3643 73.427 24.8892L75.0272 25.2763C76.6705 25.6635 77.3932 26.2313 77.3932 27.1777C77.3932 28.2789 76.2575 29.0791 74.7089 29.0791C73.0484 29.0791 71.8955 28.3306 71.7321 27.1519H69.8221Z"
      fill="#15191C"
    />
    <path
      d="M51.3348 21.3011C50.1063 21.3011 49.0437 21.9153 48.4959 22.9446H48.3631V21.4505H46.6448V33.4949H48.4295V29.1204H48.5706C49.0437 30.075 50.0647 30.6395 51.3514 30.6395C53.6341 30.6395 55.0867 28.8382 55.0867 25.9661C55.0867 23.094 53.6341 21.3011 51.3348 21.3011ZM50.8284 29.0374C49.3343 29.0374 48.3963 27.8587 48.3963 25.9744C48.3963 24.0818 49.3343 22.9031 50.8367 22.9031C52.3475 22.9031 53.2522 24.0569 53.2522 25.9661C53.2522 27.8836 52.3475 29.0374 50.8284 29.0374Z"
      fill="#15191C"
    />
    <path
      d="M61.3316 21.3011C60.103 21.3011 59.0405 21.9153 58.4927 22.9446H58.3599V21.4505H56.6416V33.4949H58.4263V29.1204H58.5674C59.0405 30.075 60.0615 30.6395 61.3482 30.6395C63.6309 30.6395 65.0835 28.8382 65.0835 25.9661C65.0835 23.094 63.6309 21.3011 61.3316 21.3011ZM60.8252 29.0374C59.3311 29.0374 58.3931 27.8587 58.3931 25.9744C58.3931 24.0818 59.3311 22.9031 60.8335 22.9031C62.3443 22.9031 63.249 24.0569 63.249 25.9661C63.249 27.8836 62.3443 29.0374 60.8252 29.0374Z"
      fill="#15191C"
    />
    <path
      d="M43.4428 30.49H45.4905L41.008 18.0752H38.9346L34.4521 30.49H36.431L37.5752 27.1949H42.3072L43.4428 30.49ZM39.8724 20.3293H40.0186L41.8168 25.5774H38.0656L39.8724 20.3293Z"
      fill="#15191C"
    />
    <path
      d="M35.6514 8.71094V14.7H37.8137C39.5984 14.7 40.6318 13.6001 40.6318 11.6868C40.6318 9.80249 39.5901 8.71094 37.8137 8.71094H35.6514ZM36.5811 9.55762H37.71C38.9509 9.55762 39.6855 10.3462 39.6855 11.6992C39.6855 13.073 38.9634 13.8533 37.71 13.8533H36.5811V9.55762Z"
      fill="#15191C"
    />
    <path
      d="M43.7969 14.7871C45.1167 14.7871 45.9261 13.9031 45.9261 12.438C45.9261 10.9812 45.1126 10.093 43.7969 10.093C42.4771 10.093 41.6636 10.9812 41.6636 12.438C41.6636 13.9031 42.4729 14.7871 43.7969 14.7871ZM43.7969 13.9944C43.0208 13.9944 42.585 13.4258 42.585 12.438C42.585 11.4585 43.0208 10.8857 43.7969 10.8857C44.5689 10.8857 45.0088 11.4585 45.0088 12.438C45.0088 13.4216 44.5689 13.9944 43.7969 13.9944Z"
      fill="#15191C"
    />
    <path
      d="M52.8182 10.1802H51.9259L51.1207 13.6292H51.0501L50.1205 10.1802H49.2655L48.3358 13.6292H48.2694L47.4601 10.1802H46.5553L47.8004 14.7H48.7176L49.6473 11.3713H49.7179L50.6517 14.7H51.5772L52.8182 10.1802Z"
      fill="#15191C"
    />
    <path
      d="M53.8458 14.7H54.7382V12.0562C54.7382 11.3506 55.1574 10.9106 55.8173 10.9106C56.4772 10.9106 56.7926 11.2717 56.7926 11.998V14.7H57.685V11.7739C57.685 10.699 57.1288 10.093 56.1203 10.093C55.4396 10.093 54.9914 10.396 54.7714 10.8982H54.705V10.1802H53.8458V14.7Z"
      fill="#15191C"
    />
    <path d="M59.0903 14.7H59.9826V8.41626H59.0903V14.7Z" fill="#15191C" />
    <path
      d="M63.3386 14.7871C64.6584 14.7871 65.4678 13.9031 65.4678 12.438C65.4678 10.9812 64.6543 10.093 63.3386 10.093C62.0188 10.093 61.2053 10.9812 61.2053 12.438C61.2053 13.9031 62.0146 14.7871 63.3386 14.7871ZM63.3386 13.9944C62.5625 13.9944 62.1267 13.4258 62.1267 12.438C62.1267 11.4585 62.5625 10.8857 63.3386 10.8857C64.1106 10.8857 64.5505 11.4585 64.5505 12.438C64.5505 13.4216 64.1106 13.9944 63.3386 13.9944Z"
      fill="#15191C"
    />
    <path
      d="M68.1265 14.0234C67.6409 14.0234 67.2881 13.7869 67.2881 13.3801C67.2881 12.9817 67.5704 12.77 68.1929 12.7285L69.2969 12.658V13.0356C69.2969 13.5959 68.7989 14.0234 68.1265 14.0234ZM67.8982 14.7747C68.4917 14.7747 68.9856 14.5173 69.2554 14.0649H69.326V14.7H70.1851V11.6121C70.1851 10.6575 69.5459 10.093 68.4129 10.093C67.3877 10.093 66.6573 10.5911 66.566 11.3672H67.4292C67.5289 11.0476 67.8733 10.865 68.3714 10.865C68.9815 10.865 69.2969 11.1348 69.2969 11.6121V12.0022L68.0726 12.0728C66.9976 12.1392 66.3916 12.6082 66.3916 13.4216C66.3916 14.2476 67.0267 14.7747 67.8982 14.7747Z"
      fill="#15191C"
    />
    <path
      d="M73.2132 14.7747C73.8358 14.7747 74.3629 14.48 74.6327 13.9861H74.7032V14.7H75.5582V8.41626H74.6659V10.8982H74.5995C74.3546 10.4001 73.8316 10.1055 73.2132 10.1055C72.0719 10.1055 71.3373 11.0103 71.3373 12.438C71.3373 13.8699 72.0636 14.7747 73.2132 14.7747ZM73.4664 10.9065C74.2135 10.9065 74.6825 11.5 74.6825 12.4421C74.6825 13.3884 74.2176 13.9736 73.4664 13.9736C72.711 13.9736 72.2586 13.3967 72.2586 12.438C72.2586 11.4875 72.7152 10.9065 73.4664 10.9065Z"
      fill="#15191C"
    />
    <path
      d="M81.3447 14.7871C82.6645 14.7871 83.4738 13.9031 83.4738 12.438C83.4738 10.9812 82.6604 10.093 81.3447 10.093C80.0249 10.093 79.2114 10.9812 79.2114 12.438C79.2114 13.9031 80.0207 14.7871 81.3447 14.7871ZM81.3447 13.9944C80.5686 13.9944 80.1328 13.4258 80.1328 12.438C80.1328 11.4585 80.5686 10.8857 81.3447 10.8857C82.1166 10.8857 82.5566 11.4585 82.5566 12.438C82.5566 13.4216 82.1166 13.9944 81.3447 13.9944Z"
      fill="#15191C"
    />
    <path
      d="M84.655 14.7H85.5474V12.0562C85.5474 11.3506 85.9666 10.9106 86.6265 10.9106C87.2864 10.9106 87.6018 11.2717 87.6018 11.998V14.7H88.4941V11.7739C88.4941 10.699 87.938 10.093 86.9294 10.093C86.2488 10.093 85.8005 10.396 85.5806 10.8982H85.5142V10.1802H84.655V14.7Z"
      fill="#15191C"
    />
    <path
      d="M92.6039 9.05542V10.2009H91.8858V10.9521H92.6039V13.5046C92.6039 14.3762 92.9981 14.7249 93.9901 14.7249C94.1644 14.7249 94.3304 14.7041 94.4757 14.6792V13.9363C94.3512 13.9487 94.2723 13.957 94.1353 13.957C93.6913 13.957 93.4962 13.7495 93.4962 13.2764V10.9521H94.4757V10.2009H93.4962V9.05542H92.6039Z"
      fill="#15191C"
    />
    <path
      d="M95.6735 14.7H96.5658V12.0603C96.5658 11.3755 96.9726 10.9148 97.703 10.9148C98.3339 10.9148 98.6701 11.28 98.6701 12.0022V14.7H99.5624V11.7822C99.5624 10.7073 98.9689 10.0972 98.006 10.0972C97.3253 10.0972 96.848 10.4001 96.6281 10.9065H96.5575V8.41626H95.6735V14.7Z"
      fill="#15191C"
    />
    <path
      d="M102.781 10.8525C103.441 10.8525 103.873 11.3132 103.894 12.0229H101.611C101.661 11.3174 102.122 10.8525 102.781 10.8525ZM103.89 13.4797C103.724 13.8325 103.354 14.0276 102.815 14.0276C102.101 14.0276 101.64 13.5254 101.611 12.7327V12.6829H104.803V12.3716C104.803 10.9521 104.043 10.093 102.786 10.093C101.511 10.093 100.702 11.0103 100.702 12.4546C100.702 13.8989 101.495 14.7871 102.79 14.7871C103.823 14.7871 104.545 14.2891 104.749 13.4797H103.89Z"
      fill="#15191C"
    />
    <path
      d="M24.769 20.3008C24.7907 18.6198 25.6934 17.0292 27.1256 16.1488C26.2221 14.8584 24.7088 14.0403 23.1344 13.9911C21.4552 13.8148 19.8272 14.9959 18.9715 14.9959C18.0992 14.9959 16.7817 14.0086 15.363 14.0378C13.5137 14.0975 11.7898 15.1489 10.8901 16.7656C8.95607 20.1141 10.3987 25.0351 12.2513 27.7417C13.1782 29.0671 14.2615 30.5475 15.6789 30.495C17.066 30.4375 17.584 29.6105 19.2583 29.6105C20.9171 29.6105 21.4031 30.495 22.8493 30.4616C24.3377 30.4375 25.2754 29.1304 26.1698 27.7925C26.8358 26.8481 27.3483 25.8044 27.6882 24.7C25.9391 23.9602 24.771 22.2 24.769 20.3008Z"
      fill="#15191C"
    />
    <path
      d="M22.0373 12.2111C22.8489 11.2369 23.2487 9.98469 23.1518 8.72046C21.912 8.85068 20.7668 9.44324 19.9443 10.3801C19.14 11.2954 18.7214 12.5255 18.8006 13.7415C20.0408 13.7542 21.2601 13.1777 22.0373 12.2111Z"
      fill="#15191C"
    />
  </Icon>
)
export default ApplePlayIcon
