import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Image,
  useDisclosure,
} from '@chakra-ui/react'
import { BAEntry } from '../hooks/useContentstack'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

export interface BannerAnnouncementProps {
  children: BAEntry[]
}

export const BannerAnnouncement = ({ items, analyticsTrackingData, monetateBanner, text_color, content_alignment }) => {
  const { isOpen, onClose } = useDisclosure()
  const [activeIndex, setActiveIndex] = useState(0)
  const [onOpen, setOpen] = useState(false)
  const [timer, setTimer] = useState(3000)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (hover) return

    setTimeout(() => {
      if (timer <= 0) {
        setTimer(3000)
        setActiveIndex((prevIndex) => (prevIndex + 1) % items?.length)
      } else setTimer(timer - 100)
    }, 100)
  }, [timer, hover])

  const handleClick = function (e) {
    promoClickUtagLinkEvent(getPromoName(e.target?.href, '1'))

    if (e.target.innerText == 'View Details') {
      setOpen(true)
    } else {
      setOpen(false)
      setHover(false)
    }
  }

  const getPromoName = (href, relativeIndex) => {
    const analyticsLocationIdentifier = extractPath(href ?? '')
    const dataPromotionName =
      analyticsTrackingData && `${analyticsTrackingData} | ${relativeIndex} | ${analyticsLocationIdentifier}`

    return dataPromotionName
  }

  const handleFocus = () => {
    setHover(true)
  }

  const handleBlur = () => {
    setHover(false)
  }

  return monetateBanner && <Box id="mt_slideshow" mx={{ base: -4, md: 0 }}></Box>
}
